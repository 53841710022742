<template>
  <div class="sidebar">
    <header>
      <router-link to="/">
        <img src="@/assets/images/logo.png">
      </router-link>
      <p v-if="$root.$data.me != null">
        <router-link to="/">Home</router-link> | 
        <router-link to="/news">Nieuws</router-link> | 
        <a href="#" @click.prevent="logout()">Uitloggen</a>
      </p>
    </header>
    
    <ul class="menu-items" v-if="$root.$data.me != null">
      <li v-if="$root.$data.me.AllowContractManagement"><router-link :to="{ name: 'contract-search' }">Contracten</router-link></li>
      <li v-if="$root.$data.me.AllowUserManagement"><router-link :to="{ name: 'user-search' }">Gebruikers</router-link></li>
      <li v-if="$root.$data.me.AllowLabelManagement"><router-link :to="{ name: 'label-search' }">Labels</router-link></li>

      <li><router-link to="/playlists">Mijn playlists</router-link></li>
      <li><router-link to="/tracks">Mijn tracks</router-link></li>

      <li><router-link to="/playlist-concepts-create">Nieuwe playlist</router-link></li>
      <li><router-link to="/trends">Trends</router-link></li>

      <li v-if="$root.$data.me.AllowAnalytics"><router-link to="/royalty-analytics">Royalty analytics</router-link></li>
      <li v-if="$root.$data.me.AllowRoyalty"><router-link to="/royalty-accounting">Royalty accounting</router-link></li>
      <li v-if="$root.$data.me.AllowRoyalty || $root.$data.me.AllowAnalytics"><router-link to="/exports">Exports</router-link></li>
    </ul>

    <div id="sidebar-info" class="info" v-if="$root.$data.me != null">
      <strong>Ingelogd als:</strong><br>
      {{ $root.$data.me.Username }}<br>
      <br>
      {{ $root.$data.me.ContractID }}<br>

      <div class="mt-4" v-if="$root.$data.adminToken">
        <a href="#" @click.prevent="stopImpersonating()">Schakel terug naar Admin</a>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import * as Sentry from '@sentry/browser'

  export default {
    name: 'SidebarMenu',
    components: {

    },
    methods: {
      logout () {
        localStorage.removeItem('jwt')
        localStorage.removeItem('adminToken')
        Cookies.remove('jwt')
        Sentry.setUser(null)
        history.go()
      },
      stopImpersonating() {
        localStorage.setItem('jwt', this.$root.$data.adminToken)
        localStorage.removeItem('adminToken')
        location.href = '/'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  .sidebar {
    * { color: white; }
    
    position: fixed;
    width: $sidebar-width;
    left: 0;
    top: 0;
    bottom: 0;
  }

  header {
    img { margin-bottom: 1em; }
    p {
      margin-left: 1em;
    }
    
    background-color: $color-dark-purple;
    margin-bottom: 0;
    overflow: auto;
  }

  .menu-items {
    li {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    margin: 0;
    background-color: $color-light-purple;
    list-style: none;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .info {
    &, * { color: black; }
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    padding: 15px 10px;
  }
</style>