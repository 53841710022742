var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c(
      "header",
      [
        _c("router-link", { attrs: { to: "/" } }, [
          _c("img", { attrs: { src: require("@/assets/images/logo.png") } })
        ]),
        _vm.$root.$data.me != null
          ? _c(
              "p",
              [
                _c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")]),
                _vm._v(" | "),
                _c("router-link", { attrs: { to: "/news" } }, [
                  _vm._v("Nieuws")
                ]),
                _vm._v(" | "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.logout()
                      }
                    }
                  },
                  [_vm._v("Uitloggen")]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm.$root.$data.me != null
      ? _c("ul", { staticClass: "menu-items" }, [
          _vm.$root.$data.me.AllowContractManagement
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "contract-search" } } },
                    [_vm._v("Contracten")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.AllowUserManagement
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "user-search" } } },
                    [_vm._v("Gebruikers")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.AllowLabelManagement
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "label-search" } } },
                    [_vm._v("Labels")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/playlists" } }, [
                _vm._v("Mijn playlists")
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/tracks" } }, [
                _vm._v("Mijn tracks")
              ])
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: "/playlist-concepts-create" } },
                [_vm._v("Nieuwe playlist")]
              )
            ],
            1
          ),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/trends" } }, [
                _vm._v("Trends")
              ])
            ],
            1
          ),
          _vm.$root.$data.me.AllowAnalytics
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/royalty-analytics" } }, [
                    _vm._v("Royalty analytics")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.AllowRoyalty
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/royalty-accounting" } }, [
                    _vm._v("Royalty accounting")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.$root.$data.me.AllowRoyalty || _vm.$root.$data.me.AllowAnalytics
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/exports" } }, [
                    _vm._v("Exports")
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm.$root.$data.me != null
      ? _c("div", { staticClass: "info", attrs: { id: "sidebar-info" } }, [
          _c("strong", [_vm._v("Ingelogd als:")]),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$root.$data.me.Username)),
          _c("br"),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$root.$data.me.ContractID)),
          _c("br"),
          _vm.$root.$data.adminToken
            ? _c("div", { staticClass: "mt-4" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.stopImpersonating()
                      }
                    }
                  },
                  [_vm._v("Schakel terug naar Admin")]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }